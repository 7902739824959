import axios from "axios";

const API = "routing";

const drivers = {
    listarDrivers: (params) => axios(`${API}/driver`, { params }),
    getDriver: (params) => axios(`${API}/driver/${params.id}`),
    crearDriver: (params) => axios.post(`${API}/driver`, params),
    editarDriver: (params) => axios.put(`${API}/driver/${params.id}`, params),
    cambiarEstadoDriver: (id) => axios.put(`${API}/driver-cambiar-estado/${id}`),
    deleteDriver: (id) => axios.delete(`${API}/driver/${id}`),
};

export default drivers;
