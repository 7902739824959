import axios from "axios";

const API = "retiros";

const confirmacionPrecio = {    
    indexContar: params => axios.get(`${API}/index-contar`, {params}),
    indexContarRetiros: params => axios.get(`${API}/index-contar-retiros`, {params}),
    listar: params => axios.get(`${API}/listar`, { params }),
    checkMulti: data => axios.put(`${API}/check-multi`, data),
    confirmar: data => axios.put(`costo/confirmar`, data),

};

export default confirmacionPrecio;
