<template>
    <section class="pt-3 px-3">
        <div class="row mx-0">
            <div class="col px-0 bg-white br-t-12 mx-2">
                <div class="row mx-0 align-items-center border-bottom" style="height:50px;">
                    <div class="bg-general3 cr-pointer text-white d-middle-center rounded-circle" style="width:25px;height:25px;" @click="goBack()">
                        <i class="icon-left f-18" />
                    </div>
                    <div class="col-auto f-22 text-general f-600">
                        {{ driver.nombre }}
                    </div>
                    <div class="btn-ruta ml-auto d-middle-center cr-pointer border br-8" @click="editarDriver">
                        <i class="icon-pencil-outline" />
                    </div>
                    <div class="btn-ruta d-middle-center ml-2 cr-pointer border br-8" @click="eliminarDriver">
                        <i class="icon-delete-outline" />
                    </div>
                </div>
                <div class="custom-scroll overflow-auto pt-4" style="height:calc(100vh - 125px)">
                    <div class="row mx-0">
                        <div class="col-auto">
                            <img :src="driver.imagen" class="obj-cover br-6" width="162" height="162" />
                        </div>
                        <div class="col">
                            <div class="row mx-0 align-items-center">
                                <i class="icon-truck text-general f-20" />
                                <div class="col-auto text-general">
                                    <p>{{ driver.placa || 'Sin placa' }}</p>
                                    <p>Vehículo</p>
                                </div>
                                <el-tooltip placement="bottom" content="Servicios del vehiculo" effect="light">
                                    <div class="btn-ruta d-middle-center ml-2 border br-8" @click="serviciosVehiculo">
                                        <i class="icon-info-circled-alt" />
                                    </div>
                                </el-tooltip>
                            </div>
                            <p class="text-general mt-3">
                                <b>Número de documento:</b>
                                {{ driver.identificacion }}
                            </p>
                            <p class="text-general mt-3">
                                <b>Teléfono celular</b>
                                {{ driver.telefono }}
                            </p>
                            <p class="text-general mt-3">
                                <b>id_cargamos</b>
                                {{ driver.cargamos_id }}
                            </p>
                        </div>
                    </div>
                    <div class="row mx-0 py-4" />
                    <div class="row mx-0 text-general f-600">
                        <div class="col-auto">
                            Calificación
                        </div>
                        <div class="col-auto">
                            {{ driver.calificacion || 'Sin calificación' }} <i class="icon-star text-warning ml-2 f-20" />
                        </div>
                    </div>
                    <div class="row mx-0 py-4" />
                    <div class="row mx-0">
                        <div class="col-12 mb-3 text-general f-17 f-600">
                            Entregas realizadas y programadas
                        </div>
                        <div class="col bg-tema text-center py-2 br-12 px-0 mx-1 text-general f-15">
                            Última semana
                            <b class="ml-2">
                                {{ agregarSeparadoresNumero(driver.entregas_semana) }}
                            </b>
                        </div>
                        <div class="col bg-tema text-center py-2 br-12 px-0 mx-1 text-general f-15">
                            Hoy
                            <b class="ml-2">
                                {{ agregarSeparadoresNumero(driver.entregas_hoy) }}
                            </b>
                        </div>
                        <div class="col bg-tema text-center py-2 br-12 px-0 mx-1 text-general f-15">
                            Mañana
                            <b class="ml-2">
                                {{ agregarSeparadoresNumero(driver.entregas_manana) }}
                            </b>
                        </div>
                    </div>
                    <div class="row mx-0 py-4" />
                    <div class="row mx-0">
                        <div class="col-12 text-general f-17 f-600">
                            Entregas del último mes
                        </div>
                        <div class="col-12 mt-3 text-general text-center f-17 f-600">
                            <b>{{ agregarSeparadoresNumero(entregasMes) }} Pedidos</b> <span :class="`${colorPorcentaje} f-600`">{{ agregarSeparadoresNumero(porcentaje) }}%</span>
                        </div>
                        <div class="col-12">
                            <echart :options="calendario" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col px-0 bg-white br-t-12 mx-2">
                <historial v-if="!turno" @verHistorial="mostrarTurno" />
                <turno v-else @verTurno="mostrarTurno(false)" />
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalServicio" titulo="Servicios del vehículo" no-aceptar no-cancelar tamano="modal-sm">
            <div v-for="(placa, p) in turnosVehiculos" :key="p" class="row mx-0 align-items-center justify-center my-2">
                <div class="col-auto f-20">
                    <i class="icon-truck text-general f-22" />
                    <span class="ml-2 text-general">{{ placa.placa }}</span>
                </div>
                <div class="col-auto text-general f-20">
                    {{ agregarSeparadoresNumero(placa.servicios) }}
                </div>
            </div>
        </modal>
        <modal-eliminar ref="modalEliminar" titulo="Eliminar Driver" mensaje="¿Desea eliminar este driver?" @eliminar="deleteDriver" />
        <modal-editar-driver ref="modalEditarDriver" @editar="editar" />
    </section>
</template>

<script>

import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);
import Drivers from "~/services/routing/drivers";
import { mapGetters } from 'vuex'
let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-db')

export default {
    components: {
        historial: () => import('./components/historial'),
        turno: () => import('./components/turno'),
        modalEditarDriver: () => import('../partials/modalEditarDriver')
    },
    data(){
        let self = this
        return{
            url_persona: 'https://concepto.de/wp-content/uploads/2018/08/persona-e1533759204552.jpg',
            calendario: {
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.dia}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" />
                        <span class="text-general2">
                        ${obj[0].seriesName}
                        ${self.agregarSeparadoresNumero(obj[0].data.cantidad)}</span>`  + '<br>'
                        + '</div>'
                    }
                },
                xAxis: [
                    {
                        // name: 'Valor',
                        type: 'category',
                        scale: true,
                        axisLabel: {
                            color: '#6D6B6B',
                            interval: 1
                        },
                        show: false
                    },
                ],
                dataset:{
                    dimensions: ['dia','cantidad'],
                    source: []
                },
                yAxis: [
                    {
                        type: 'value',
                        boundaryGap: true,
                    },
                ],
                series: [
                    {
                        type: "bar",
                        name: "Pedidos",
                        barWidth: "20",
                        itemStyle: {
                            normal: {
                                color: colorGeneral2
                            }
                        }
                    },
                ]
            },
            driver: {
                calificacion: null,
                cargamos_id: null,
                carrito: null,
                created_at: null,
                created_by: null,
                entregas_hoy: null,
                entregas_manana: null,
                entregas_semana: null,
                foto: null,
                id: null,
                identificacion: null,
                imagen: null,
                nombre: null,
                placa: null,
                servicios: null,
                telefono: null,
                ultimo_turno: null,
                updated_at: null,
                updated_by: null,
            },
            turnosVehiculos: [],
            entregasMes: 0,
            porcentaje: 0,
            turno: false,
        }
    },
    computed: {
        colorPorcentaje(){
            let color = ''
            if (this.porcentaje < 0){
                color = 'text-general-red'
            }else if(this.porcentaje > 0){
                color = 'text-success'
            }
            return color
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                this.getDriver()
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        async getDriver(){
            try {
                const params = {
                    id: this.$route.params.id_driver
                }
                const { data } = await Drivers.getDriver(params)
                this.driver = data.driver
                this.calendario.dataset.source = data.entregas
                this.entregasMes = data.entregasMes
                this.porcentaje = data.porcentaje
                this.turnosVehiculos = data.turnosVehiculos
            } catch (error){
                this.error_catch(error)
            }
        },
        serviciosVehiculo(){
            this.$refs.modalServicio.toggle();
        },
        eliminarDriver(){
            this.$refs.modalEliminar.toggle();
        },
        mostrarTurno(state){
            this.turno = state;
        },
        editar(model){
            this.driver.nombre = model.nombre
            this.driver.telefono = model.telefono
            this.driver.identificacion = model.identificacion
            this.driver.cargamos_id = model.cargamos_id
            this.driver.foto = model.foto
            this.driver.imagen = model.imagen
        },
        editarDriver(){
            let model = {
                id: this.driver.id,
                nombre: this.driver.nombre,
                telefono: this.driver.telefono,
                identificacion: this.driver.identificacion,
                cargamos_id: this.driver.cargamos_id,
                foto: this.driver.foto,
                imagen: this.driver.imagen,
            }
            this.$refs.modalEditarDriver.toggle(model);
        },
        eliminarContacto(){
            this.$refs.modalEliminar.toggle();
        },
        async deleteDriver(){
            try {
                const {data} = await Drivers.deleteDriver(this.driver.id)
                this.notificacion('', data.message, 'success')
                this.$refs.modalEliminar.toggle()
                this.goBack()
            } catch (e){
                this.error_catch(e)
            }
        },
        goBack(){
            this.$router.push({name: 'admin.rutas.drivers'})
        }
    }
}
</script>
<style lang="scss" scoped>
    .btn-ruta{
        width: 32px;
        height: 32px;
        background-image: linear-gradient(to bottom, #FFFFFF, #F3F5F7);
        color: var(--text-general) !important;
    }
    .bg-tema{
        background-color: #F6F9FB;
    }
</style>
