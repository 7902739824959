import axios from "axios";

const API = "routing";

const turnos = {
    listarTurnos: (params = {}) => axios(`${API}/turno`, { params }),
    getTurno: (params = {}) => axios(`${API}/turno/${params.id}`, { params }),
    crearTurno: (params) => axios.post(`${API}/turno`, params),
    editarTurno: (params) => axios.put(`${API}/turno/${params.id}`, params),
    cambiarEstadoTurno: (id) => axios.put(`${API}/turno-cambiar-estado/${id}`),
    deleteTurno: (id) => axios.delete(`${API}/turno/${id}`),
};

export default turnos;
