<template>
    <section>
        Ver el mapa
    </section>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>
