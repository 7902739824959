<template>
    <section>
        <tabs :tabs="tabs" class="border-bottom" />
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 169px)">
            <router-view />
        </div>
    </section>
</template>

<script>
import confirmacionPrecio from  '~/services/retiros/confirmacion_precio'
import { mapGetters } from 'vuex'
export default {
    middleware:['auth'],
    data(){
        return {
            cambioPrecio: 0,
            retirosProductos: 0,
            confirmacionPrecio: 0,
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        tabs(){
            return [
                { titulo: 'Productos retirados', ruta: 'admin.retiros.productos', num: this.retirosProductos, can: 'tab1_gestion_admin_retiros' },
                { titulo: 'Precios sugeridos', ruta: 'admin.retiros.cambio-precio', num: this.cambioPrecio, can: 'tab1_gestion_admin_cambio_precio' },
                { titulo: 'Precios proveedor', ruta: 'admin.retiros.confirmacion-precio', num: this.confirmacionPrecio, can: 'tab1_gestion_precios_proveedor' },
                { titulo: 'Productos descartados', ruta: 'admin.retiros.estadisticas-eliminacion', icono: 'icon-chart-line', can: 'tab1_gestion_admin_eliminacion' },
                { titulo: 'Productos devueltos', ruta: 'admin.retiros.estadisticas-devolucion', icono: 'icon-chart-line', can: 'tab1_gestion_admin_devolucion' },
                { titulo: 'Productos eliminados', ruta: 'admin.retiros.eliminacion-productos', icono: 'icon-chart-line', can: 'tab1_gestion_admin_productos_eliminados' },
                { titulo: 'Términos y condiciones', ruta: 'admin.retiros.terminos-condiciones', can: 'tab1_gestion_terminos_y_condiciones' },
            ]
        }
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            if(this.id_cedis === null || this.id_moneda === null){
                setTimeout(async() => {
                    await this.indexContar()
                }, 1000);
            }else{
                await this.indexContar()
            }
        },
        async indexContar(){
            try {
                const params = {
                    id_cedis: this.id_cedis,
                    id_moneda: this.id_moneda,
                }
                const { data } = await confirmacionPrecio.indexContar(params);
                this.cambioPrecio = data.cambioPrecio
                this.retirosProductos = data.retirosProductos
                this.confirmacionPrecio = data.confirmacionPrecio
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
