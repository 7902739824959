<template>
    <section class="col-12">
        <tabla-general 
        :data="dataTable" 
        :columnas="dataColumns" 
        alto="calc(100vh - 270px)" 
        :usar-paginacion="false"
        class-header="text-general f-500 f-15"
        >   
            <template slot="cabecera-izquierda">
                <div class="col-auto text-general f-17 f-600">
                    Productos de la ruta
                </div>
            </template>
            <template slot="adicionales-izquierda">
                <el-table-column
                label="Img"
                class-name="text-center"
                width="80"
                >
                    <template slot-scope="scope">
                        <img :src="scope.row.url" width="51" height="51" />
                    </template>
                </el-table-column>
            </template>
        </tabla-general>
    </section>
</template>

<script>
export default {
    data(){
        return{
            dataTable: [
                {
                    url: 'https://www.las2orillas.co/wp-content/uploads/2021/04/biscolata-600x600.jpeg',
                    producto: '974584 - Brownie Arequipe - Mr Brown de Bimbo - 78 g',
                    cant: 25,
                    entregado: 25
                }
            ],
            dataColumns: [
                { valor: 'producto', titulo: 'Producto', ancho: 180},
                { valor: 'cant', titulo: 'Cantidad' , class: 'text-center', ancho: 180},
                { valor: 'entregado', titulo: 'Entregado', class: 'text-center' , ancho: 180}
            ]
        }
    }
}
</script>