<template>
    <section class="mx-3 pt-2">
        <tabs :tabs="rutas" class="border-bottom" />
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 120px)">
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return{
            rutas: [
                { ruta: 'admin.rutas.turno', titulo: 'Turno de rutas' },
                { ruta: 'admin.rutas.drivers', titulo: 'Drivers' }
            ]
        }
    }
}
</script>
