<template>
    <section class="pt-3">
        <div class="row align-items-center mx-0 px-5">
            <div class="bg-general3 d-middle-center cr-pointer rounded-circle text-white" style="width:22px;height:22px;" @click="$router.push({name: 'admin.rutas.ver'})">
                <i class="icon-left" />
            </div>
            <div class="col-2">
                <el-select v-model="value" class="w-100 select-transparent">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </div>
            <div class="col-auto ml-3 d-middle text-general">
                <span class="mr-2">Ruta activa</span>
                <el-switch
                v-model="ruta_activa"
                active-color="#28D07B"
                inactive-color="#DBDBDB" 
                />
            </div>
            <div class="col-auto ml-auto px-2">
                <div class="btn-ruta cr-pointer d-middle-center border br-8" @click="eliminarRuta">
                    <i class="icon-delete-outline text-general f-20" />
                </div>
            </div>
            <div class="col-auto px-2">
                <div class="border text-general px-2 br-8 cr-pointer bg-white d-middle-center" style="height:32px;" @click="enviarPedido">
                    Enviar a otro turno
                </div>
            </div>
            <div class="col-auto px-2">
                <div class="bg-general text-white shadow br-8 d-middle-center px-2 cr-pointer" style="height:32px;">
                    Guardar
                </div>
            </div>
        </div>
        <div class="row mx-0 px-3 mt-3">
            <div class="col-4 px-0 br-t-12 bg-white mx-3">
                <div class="row mx-0 align-items-center border-bottom" style="height:50px">
                    <div class="col text-general">
                        <p>Peso: 1000 kg</p>
                        <p>Volumen: 10 m3</p>
                    </div>
                    <div class="bg-general3 text-white rounded-circle d-middle-center" style="width:20px;height:20px;">
                        5
                    </div>
                    <i class="icon-swap-vertical-bold-up text-general f-20 mx-2 cr-pointer" @click="ordenar" />
                </div>
                <div class="custom-scroll overflow-auto pt-3 pr-3" style="height:calc(100vh - 178px)">
                    <draggable handle=".handle">
                        <div v-for="data in 10" :key="data" class="row mx-0 align-items-center mb-3">
                            <i class="icon-drag handle text-gris f-25" />
                            <div class="col border br-8 py-2">
                                <div class="row mx-0">
                                    <i class="icon-pedidos f-18 text-general" />
                                    <div class="col text-general">
                                        <p class="f-15 f-500">
                                            No. 584512
                                        </p>
                                        <p class="f-15">
                                            03 Feb. 10:00 p.m
                                        </p>
                                    </div>
                                </div>
                                <div class="row mx-0 text-general2 align-items-center">
                                    <i class="icon-cliente f-20" />
                                    <div class="col">
                                        Juan Camilo Hernandez
                                    </div>
                                </div>
                            </div>
                        </div>
                    </draggable>
                </div>
            </div>
            <div class="col px-0 mx-3 bg-white br-t-12">
                <div class="row mx-0 border-bottom" style="height:50px">
                    <router-link 
                    :to="{name: 'admin.rutas.mapa'}" 
                    class="col-3 px-0 d-middle-center"
                    :class="$route.name == 'admin.rutas.mapa' ? 'br-bottem text-general' : 'text-gris'"
                    >
                        Mapa
                    </router-link>
                    <router-link 
                    :to="{name: 'admin.rutas.productos'}" 
                    class="col-3 px-0 d-middle-center"
                    :class="$route.name == 'admin.rutas.productos' ? 'br-bottem text-general' : 'text-gris'"
                    >
                        Productos
                        <!-- <div class="col-3 px-0 d-middle-center">
                        </div> -->
                    </router-link>
                </div>
                <router-view />
            </div>
        </div>
        <!-- Partials -->
        <modal-ordenar ref="modalOrdenar" />
        <modal-enviar-pedido ref="modalEnviarPedido" />
        <modal-eliminar ref="modalEliminarRuta" titulo="Eliminar Ruta" mensaje="¿Desea eliminar esta ruta?" />
    </section>
</template>

<script>
export default {
    components: {
        modalOrdenar: () => import('./partials/modalOrdenar'),
        modalEnviarPedido: () => import('./partials/modalEnviarPedido')
    },
    data(){
        return{
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],
            value: '',
            ruta_activa: false
        }
    },
    methods: {
        ordenar(){
            this.$refs.modalOrdenar.toggle();
        },
        enviarPedido(){
            this.$refs.modalEnviarPedido.toggle();
        },
        eliminarRuta(){
            this.$refs.modalEliminarRuta.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
a{
    text-decoration: none !important;
    &:hover{
        color: var(--text-general) !important;
    }
}
.btn-ruta{
    background-image: linear-gradient(to bottom, #FEFEFE, #F8F9FA);
    width: 32px;
    height: 32px;
}
.br-bottem{
    border-bottom: 3px solid var(--text-general);
}
</style>